import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { CashierPending } from "./components/CashierPending";
import { fetcher } from "../../utils/fetcher";
import { CashierRequest } from "./components/CashierRequest";
import { i18nEn, i18nPt } from "./assets/i18n";

export const Cashier = (props) => {
  // Translations config
  const i18n = props.jogo ? i18nPt : i18nEn;

  const navigate = useNavigate();
  const auth = useAuthUser();
  const authHeader = useAuthHeader();
  const location = useLocation();
  const user = props.user;
  const balance = props.balance;
  const venueSocket = props.venueSocket;
  const globalSocket = props.globalSocket;
  const backend = props.backend;
  const locations = props.locations;
  const selectedLocation = props.selectedLocation;
  const setSelectedLocation = props.setSelectedLocation;

  const [requestType, setRequestType] = useState(
    location.state?.suggestDeposit ? "Deposit" : undefined
  );
  const [transactionType, setTransactionType] = useState("Cash");
  const [isValid, setIsValid] = useState(false);
  const [amount, setAmount] = useState(0);
  const [pendingRequest, setPendingRequest] = useState(undefined);

  const checkRequest = async () => {
    try {
      await fetcher(
        "GET",
        authHeader(),
        backend.current + `/request/transaction/${auth().id}?status=pending`
      ).then((data) => {
        setPendingRequest(data[0]);
      });
    } catch (err) {
      console.log({ error: err });
    }
  };

  useEffect(() => {
    if (auth()?.id || props.demo) {
      checkRequest();
    } else {
      navigate("/login", {state: {redirect: location.pathname}})
    }
  }, [auth()?.id]);

  useEffect(() => {
    setIsValid(
      amount >= 5 &&
        transactionType &&
        ((requestType === "Deposit" && amount <= 100) ||
          (requestType === "Withdraw" && amount <= Math.floor(balance))) &&
        (transactionType !== "Cash" || selectedLocation)
    );
  }, [requestType, amount, transactionType, selectedLocation]);

  useEffect(() => {
    setRequestType(
      pendingRequest?.action
        ?.split("")
        .map((l, i) => (i ? l : l.toUpperCase()))
        .join("")
      ?? requestType
    );
    // setTransactionType(pendingRequest ? "Cash" : undefined);
    setAmount(pendingRequest?.amount ?? amount);
    if (!selectedLocation) {
      setSelectedLocation(
        pendingRequest
          ? locations.find((v) => v.id === pendingRequest.locationId)
          : undefined
      );
    } else {
      // TODO: check if request is set for current location, if not update and notify old and new venues
    }
  }, [pendingRequest]);

  // Default amounts
  useEffect(()=>{
    if (!pendingRequest) {
      if (requestType === "Withdraw") {
        setAmount(Math.floor(balance))
      }
      if (requestType === "Deposit") {
        setAmount(5) // deposit minimum
      }
    }
  }, [requestType])

  const cancelRequest = async () => {
    try {
      await fetcher(
        "PUT",
        authHeader(),
        backend.current + "/request/transaction/" + pendingRequest.id,
        { id: pendingRequest.id, status: "canceled" }
      ).then((data) => {
        console.log("cancel result", data);
        console.log(venueSocket);
        venueSocket.current.emit("resolveRequest", pendingRequest.id);
        setPendingRequest(undefined);
        navigate("/");
      });
    } catch (err) {
      console.log({ error: err });
    }
  };

  const makeRequest = async () => {
    try {
      await fetcher(
        "POST",
        authHeader(),
        backend.current + "/request/transaction",
        {
          userId: auth()?.id,
          action: requestType.toLowerCase(),
          amount: Number(amount),
          locationId: selectedLocation.id,
        }
      ).then((data) => {
        delete data.transactionRequest.createdAt;
        delete data.transactionRequest.updatedAt;
        delete data.transactionRequest.status;
        setPendingRequest(data.transactionRequest);
        data.transactionRequest.User = {
          username: user.username,
          phoneNumber: user.phoneNumber,
        };
        console.log("make request", data);
        venueSocket.current.emit(
          "sendRequest",
          JSON.stringify(data.transactionRequest)
        );
        console.log("request sent");
      });
    } catch (err) {
      console.log({ error: err });
    }
  };

  const PaynoteTransfer = async () => {
    const transfer = requestType.toLowerCase();
    const returnUrlBase = window.location.href.slice(
      0,
      window.location.href.indexOf("/cashier")
    );
    const body = { [transfer]: amount };
    if (transfer === "deposit") {
      body.destination = returnUrlBase + "/cashier";
      body.cancelDestination = returnUrlBase + `/cashier/${transfer}`;
    }
    if (transfer === "withdraw") {
      navigate("/");
    } // TODO: withdraw loading icon
    await fetcher(
      "POST",
      authHeader(),
      backend.current + `/paynote/${transfer}`,
      body
    ).then((res) => {
      console.log("withdraw completed");
      if (!!res.url) {
        window.location.href = res.url;
      }
      // else {navigate("/")} // TODO: popup modal
    }).finally(() => {
      globalSocket.current.emit("balanceUpdate", auth()?.id);
    }).catch((e) => {
      console.log({ error: e });
      // if (e?.response?.data?.message == "Exceeded maximum daily deposit of $100.") {
      //   setMaxDailyToastOpen(true);
      // } else {
      //   setError(e?.message)
      // }
    });
  };

  const handleClick = (e) => {
    if (!requestType) {
      navigate("/");
    } else if (isValid && transactionType === "Cash") {
      makeRequest();
    } else if (isValid && transactionType === "ACH") {
      PaynoteTransfer();
    }
  };

  return (
    <>
      {!pendingRequest ? (
        <CashierRequest
          amount={amount}
          setAmount={setAmount}
          requestType={requestType}
          setRequestType={setRequestType}
          transactionType={transactionType}
          setTransactionType={setTransactionType}
          isValid={isValid}
          handleClick={handleClick}
          user={user}
          balance={balance}
          locations={locations}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          demo={props.demo}
          jogo={props.jogo}
          jackpotValue={props.jackpotValue}
          modalOpen={props.modalOpen}
          modalData={props.modalData}
          setModalOpen={props.setModalOpen}
          setModalData={props.setModalData}
          // temporary labels as props, TODO split up component
          i18nBack={i18n.back}
          i18nMakeADeposit={i18n.makeADeposit} 
          i18nMakeAWithdrawal={i18n.makeAWithdrawal}
          i18nReturnHome={i18n.returnHome}
          i18nDepositRequest={i18n.depositRequest}
          i18nWithdrawRequest={i18n.withdrawRequest}
          i18nAmountToDeposit={i18n.amountToDeposit}
          i18nAmountToWithdraw={i18n.amountToWithdraw}
          i18nDepositType={i18n.depositType}
          i18nWithdrawalType={i18n.withdrawalType}
          i18nLocation={i18n.location}
          i18nVerifyMsg={i18n.verifyMsg}
          i18nContinue={i18n.continue}
          i18nSelectAnOption={i18n.selectAnOption}
          i18nCash={i18n.cash}
        />
      ) : (
        <CashierPending
          amount={amount}
          requestType={requestType}
          selectedLocation={selectedLocation}
          pendingRequest={pendingRequest}
          setRequestType={setRequestType}
          cancelRequest={cancelRequest}

          i18nLocation={i18n.location}
          i18nDepositRequested={i18n.depositRequested}
          i18nWithdrawalRequested={i18n.withdrawalRequested}
          i18nRequestPageInstructions1={i18n.requestPageInstructions1}
          i18nRequestPageInstructions2={i18n.requestPageInstructions2}
          i18nRequestPageInstructions3={i18n.requestPageInstructions3}
          i18nRequestPageInstructions4={i18n.requestPageInstructions4}
          i18nAmountToDeposit={i18n.amountToDeposit}
          i18nAmountToWithdraw={i18n.amountToWithdraw}
          i18nCancelRequestMsg={i18n.cancelRequestMsg}
          i18nCompleteRequestMsg={i18n.completeRequestMsg}
          i18nConfirmToCancel={i18n.confirmToCancel}
          i18nConfirm={i18n.confirm}
          i18nNo={i18n.no}
          />
      )}
    </>
  );
};
